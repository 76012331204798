<template>
  <div id="app">
    
    <el-container>
      <el-header style="display:none">
        <el-row type="flex"  justify="end" >
          <el-col :span='6'>
            <div>

              <img :src="logo" style="height:60px;">    
            </div>    
            <div>
            </div>
            
          </el-col>
          <el-col :span='18'>
            <el-menu :default-active="activeIndex" :active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="about">关于我们</el-menu-item>
              <!-- <el-menu-item index="contact">联系我们</el-menu-item> -->
            </el-menu>

          </el-col>
        </el-row>

              <el-divider ></el-divider>
      </el-header>
      <el-main style="  background-color: #f4f4f4;padding:0px;">
        <div :style="{backgroundImage:'url(' + bg+ ')', backgroundRepeat:'no-repeat', backgroundPosition:'center top', backgroundSize: 'cover'}">
          <el-row type="flex" justify="center" style="text-align:center;">
            <el-col :span="12">
              <div >
                <img :src="wx" style="width:300px;margin:20px;background-color:#f4f4f4;padding:2px;border-radius:20px;">
              </div>
              
            </el-col>
            <el-col :span="12">
              <div style="margin-top:400px;">

                <div class="title white">
                  愛以華
                </div>
                <div class="subtitle white">
                  耶路撒冷純天然護膚品
                </div>
                <div class="subsubtitle white">
                  敏感肌膚•母嬰•寶寶
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <el-row type="flex" justify="center" style="text-align:center;padding:20px;background-color:#ffffff;">
          <el-col :span="8">
            <div>
              <img :src="qrcode" style="height:120px;">
            </div>
            <div>
              微信扫描二维码进入商城
            </div>
            
          </el-col>
          <el-col  :span="1" style="border-left: 2px solid rgb(244, 244, 244);">
            
          </el-col>
          <el-col :span="8">
            <div style="text-align:left;">
              <div class="contact-title">联系方式</div>
              <div class="contact-line">客服电话：138 2358 5260</div>
              <div class="contact-line">服务时间：9:00-21:00</div>
              <div class="contact-line">爱以华（深圳）科技商贸有限公司</div>




            </div>
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <div class="footer" style="padding-top:10px;">
          <div>
            
Copyright@爱以华 2020
            
<a href="https://beian.miit.gov.cn/" class="beian">粤ICP备20022903号</a>
          </div>
          <div>爱以华（深圳）科技商贸有限公司</div>
        </div>
        
      </el-footer>
    </el-container>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      logo : require('./assets/logo.png'),
      wx: require('./assets/ayh_shop_2.png'),
      bg: require('./assets/ayh_bg.jpeg'),
      qrcode: require('./assets/qrcode.png'),
      activeIndex: 'about',
    }
  },
  methods: {
    handleSelect(index){
      this.activeIndex = index
    }
  },
}
</script>

<style>
#app{
  margin-top: 0;
  top:0;
}
  .el-header{
    height:60px;
  }
  .el-footer {

    background-color: #3f3e3e;
    color: #b1b1b1;
    text-align: center;
  }
  .beian {
    background-color: #3f3e3e;
    color: #b1b1b1;
    text-align: center;
    text-decoration: none
  }
  .el-divider{

    margin: 0px;
    margin-top:-5px;
  }
.el-menu--horizontal .el-menu-item.is-active {
    border-bottom: 2px solid #8c6d33;
    color: #303133;
}
.title{
  font-size:48px;
  
}
.gold{
  color: #8c6d33;
}
.white{
  color: #ffffff;
}
.subtitle{
  font-size:28px;
  color: #F1F1f1f1;
}
.subsubtitle{
  font-size:24px;
  color: #d1d1d1;
}
.contact-title{
  font-size:24px;
}
.contact-line{
  font-size:16px;
}
  
</style>
